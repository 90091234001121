import * as React from 'react';

export function usePopover<T extends HTMLElement>(): [
    T | null,
    boolean,
    {
        handleClose: (event: React.MouseEvent<EventTarget>) => void;
        handleListKeyDown: (event: React.KeyboardEvent<EventTarget>) => void;
        handleToggle: (event: React.MouseEvent<T>) => void;
    },
] {
    const [isOpen, setIsOpen] = React.useState(false);
    // This should be a ref, but until we forwardRef with useRef
    const [anchorRef, setAnchorRef] = React.useState<T | null>(null);

    const handleToggle = (event: React.MouseEvent<T>) => {
        setAnchorRef(event.currentTarget);
        setIsOpen(prevOpen => !prevOpen);
    };

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef && anchorRef.contains(event.target as HTMLElement)) {
            return;
        }

        setIsOpen(false);
    };

    function handleListKeyDown(event: React.KeyboardEvent<EventTarget>) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setIsOpen(false);
        }
    }

    // Return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(isOpen);
    React.useEffect(() => {
        if (prevOpen.current && !isOpen) {
            anchorRef?.focus();
        }

        prevOpen.current = isOpen;
    }, [isOpen, anchorRef]);
    return [anchorRef, isOpen, { handleClose, handleListKeyDown, handleToggle }];
}
