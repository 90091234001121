import * as React from 'react';

/**
 * Use effect but will not trigger on the first run
 *
 * @param effect - The effect
 * @param deps - The dependency list
 */
export function useEffectOnUpdateOnly(
    effect: Parameters<typeof React.useEffect>[0],
    deps: React.DependencyList,
): ReturnType<typeof React.useEffect> {
    const isInitialMount = React.useRef(true);

    // eslint-disable-next-line consistent-return
    React.useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            return effect();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
}
