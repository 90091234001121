import { Observable } from 'rxjs';
import { useConstantFn } from '../useConstantFn';

/**
 * Stores an observable as state.
 *
 * @example
 * // seconds$ is an observable with the current date, updated every second
 * const seconds$ = useObservable(() => interval(1000).pipe(map => new Date()));
 * @param init - a function that returns an observable.  this function is only called once
 * @returns an observable, initialized from the init parameter
 */
export function useObservable<Output>(init: () => Observable<Output>): Observable<Output> {
    return useConstantFn(init).current;
}
