import { debounceTime, map } from 'rxjs/operators';
import { useObservableState } from '../useObservableState';
import { useObservableEpic } from '../useObservableEpic';

export function useDebouncedValue<T>(value: T, interval = 500): T {
    const debounce$ = useObservableEpic(
        input$ =>
            input$.pipe(
                map(blah => blah[0]),
                debounceTime(interval),
            ),
        [value],
    );
    const debouncedValue = useObservableState(debounce$, value);

    return debouncedValue;
}
