import { isNullOrUndefined } from '@deltasierra/type-utilities';
import * as React from 'react';
import { useConstantFn } from '../useConstantFn';
import { useToggleRedux as useToggle } from '../useToggle/useToggleRedux';

/**
 * When you want to toggle between two arbitrary values.
 * This really shines with string literals, but theoretically you can use any values.
 * Note: the literals won't change, even if you change the inputs.
 * Whatever they are on component init, that's what they'll stay.
 * The hook doesn't really make sense if the values can change.
 *
 * @example
 *  function Lightbulb() {
 *      // Initial value is 'on'
 *      const [offOrOn, toggleOffOrOn] = useToggleValue(['off', 'on'], 'on');
 *      return (
 *          <div>
 *              <div>{`The lightbulb is currently ${offOrOn}.`}</div>
 *              <button onClick={() => toggleOffOrOn()}>{'Flip the light switch'}</button>
 *              <button onClick={() => toggleOffOrOn('off')}>{'Turn the light off'}</button>
 *              <button onClick={() => toggleOffOrOn('on')}>{'Turn the light on'}</button>
 *          </div>
 *      );
 *  }
 * @param valueTuple - A tuple, with the first and second value are the first and second desired toggleables
 * @param initial - Which value is initially toggled - defaults to secondValue
 * @returns The currently toggled value
 */
export function useToggleValue<F, S>(valueTuple: [F, S], initial?: F | S): [F | S, (val?: F | S) => void] {
    const firstValueRef = useConstantFn(() => valueTuple[0]);
    const secondValueRef = useConstantFn(() => valueTuple[1]);
    const [isFirstLiteral, toggleIsFirstLiteral] = useToggle(initial === firstValueRef.current);
    const toggle = React.useCallback(
        (val?: F | S) => {
            if (isNullOrUndefined(val)) {
                toggleIsFirstLiteral();
            } else {
                toggleIsFirstLiteral(val === firstValueRef.current);
            }
        },
        // Both are these are constant but eslint doesn't know that
        [firstValueRef, toggleIsFirstLiteral],
    );

    return [isFirstLiteral ? firstValueRef.current : secondValueRef.current, toggle];
}
