import { map } from 'rxjs/operators';
import { useConstantFn } from '../useConstantFn';
import { useObservableState } from '../useObservableState';
import { useObservableCallback } from '../useObservableCallback';

export const useRadioInput = (
    options: string[],
    initialOption: string,
): [string, (ev: React.ChangeEvent<HTMLInputElement>) => void] => {
    const optionsRef = useConstantFn(() => options);
    const [onChange, value$] = useObservableCallback<string, React.ChangeEvent<HTMLInputElement>>(input$ =>
        input$.pipe(
            map(event => event.target.value),
            map(val => optionsRef.current.includes(val) ? val : initialOption),
        ),
    );
    const value = useObservableState(value$, initialOption);

    return [value, onChange];
};
