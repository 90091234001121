import React from 'react';
import { useDebounceFn } from '../useDebounce';

export function useTemporaryValue<V>(
    displayTimeMs = 5000,
): [value: V | undefined, setTemporaryValue: (newValue: V) => void] {
    const [value, setValue] = React.useState<V>();

    const reset = useDebounceFn(() => {
        setValue(undefined);
    }, displayTimeMs);

    const setTemporaryValue = React.useCallback(
        (newValue: V) => {
            setValue(newValue);
            reset();
        },
        [reset],
    );

    return [value, setTemporaryValue];
}
