import { useEffect, useRef } from 'react';

/**
 * Returns a ref to the previous value of a React state or prop.
 * Normally, you can access this value using the callback override of React.setState.
 * Rarely, however, this doesn't work, such as comparing old values of props.
 * This hook is for this situation.
 *
 * @example
 * const prev1 = usePrevious(props.oneProp);
 * const prev2 = usePrevious(props.anotherProp);
 *
 * React.useEffect(() => {
 *      if (props.oneProp !== prev1) {
 *          console.log('This prop changed!');
 *      }
 *      if (props.anotherProp !== prev2) {
 *          console.log('This other prop changed!');
 *      }
 * }, []);
 * @param value - The react state to track
 * @returns - the previous value
 */
export const usePrevious = <T>(value: T): T | undefined => {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};
