import * as React from 'react';

export const useWindowSize = (): { height: number; width: number } => {
    const getSize = React.useCallback(
        () => ({
            height: window.innerHeight,
            width: window.innerWidth,
        }),
        [],
    );

    const [size, setSize] = React.useState(getSize());

    React.useEffect(() => {
        const handleResize = () => {
            setSize(getSize());
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });

    return size;
};
