import * as React from 'react';

export function sortObjectArray<T>(array: T[], propertyName: keyof T, direction: SortDirection): T[] {
    return array.slice().sort((obj1, obj2) => {
        const property1 = obj1[propertyName];
        const property2 = obj2[propertyName];
        const convertedProperty1 = typeof property1 === 'string' ? property1.toLowerCase() : property1;
        const convertedProperty2 = typeof property2 === 'string' ? property2.toLowerCase() : property2;

        if (convertedProperty1 < convertedProperty2) {
            return direction === 'asc' ? -1 : 1;
        } else if (convertedProperty1 > convertedProperty2) {
            return direction === 'asc' ? 1 : -1;
        } else {
            return 0;
        }
    });
}

export type SortDirection = 'asc' | 'desc' | null;

export type HeaderKeys<T> = Array<{ label: string; key: keyof T }>;

export type SortableHeaders = Array<{ label: string; direction: SortDirection; onClick: () => void }>;

export type SortParameters<T> = { key: keyof T; direction: SortDirection };

/**
 * Hook that wraps logic for sortable columns in tables.
 *
 * @param headers - labels and keys of headers to be made sortable
 * @param onSort - callback for when sorting is triggers
 * @returns
 * sortHeaders - label, sort direction and onClick callback for sortable headers
 * sortParams - the current sort header key and direction or null of none are sorted
 * @example
 * function UsersTable({onSort}: UsersTableProps): JSX.Element {
 *  const headers: HeaderKeys<UserFragmentForUsersTable> = React.useMemo(
 *      () => [
 *          { key: 'firstName', label: t('COMMON.NAME') },
 *          { key: 'username', label: t('COMMON.EMAIL_ADDRESS') },
 *          { key: 'lastActivity', label: t('COMMON.LAST_ACTIVITY') },
 *      ],
 *  []);
 *
 *   const { sortHeaders } = useSortableHeaders(headers, onSort);
 *
 *  return (
 *      <DSTable>
 *          <DSTableHeader>
 *              <DSTableRow>
 *                  {sortHeaders.map(header => (
 *                      <SortableTableHeaderCell
 *                          direction={header.direction}
 *                          label={header.label}
 *                          onClick={header.onClick}
 *                       />
 *                  ))}
 *              </DSTableRow>
 *          </DSTableHeader>
 *          <DSTableBody>
 *              ...
 *          </DSTableBody>
 *      </DSTable>
 *  );
 * }
 */
export function useSortableHeaders<T>(
    headers: HeaderKeys<T>,
    onSort?: (sortParams: SortParameters<T> | null) => void,
): {
    sortHeaders: SortableHeaders;
    sortParams: SortParameters<T> | null;
} {
    const [sort, setSort] = React.useState<SortParameters<T> | null>(null);

    const handleClick = React.useCallback(
        (key: keyof T) => {
            let sortParams: SortParameters<T> | null = null;
            if (sort?.key === key) {
                if (sort.direction === 'asc') {
                    sortParams = { direction: 'desc', key };
                } else {
                    sortParams = { direction: 'asc', key };
                }
            } else {
                sortParams = { direction: 'asc', key };
            }
            setSort(sortParams);
            onSort?.(sortParams);
        },
        [onSort, sort?.direction, sort?.key],
    );

    return {
        sortHeaders: headers.map(header => ({
            direction: header.key === sort?.key ? sort.direction : null,
            label: header.label,
            onClick: () => handleClick(header.key),
        })),
        sortParams: sort ? { direction: sort.direction, key: sort.key } : null,
    };
}
