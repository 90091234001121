import * as React from 'react';
import { BehaviorSubject, Observable } from 'rxjs';
import { useConstantFn } from '../useConstantFn';

/**
 * UseObservableEpic takes an epic function and a list of inputs, and returns an observable
 * that emits the input list after it has passed through the epic
 *
 * @example
 *
 * // returns a loading state that emits false if either data set is loading, and true if neither are loading
 * const loading$ = useObservableEpic(
 *     input$ => input$.pipe(map((data1, data2) => data1.loading || data2.loading)),
 *     [props.data1, props.data2],
 * );
 *
 * // Do something with loading$, for example, create a state with it
 * const loading = useObservableState(loading$);
 * @param epic - a function that takes an observable as param and outputs another observable
 * @param inputs - values to watch for changes.. The changed value array is what is emitted by
 * the returned observable
 * @returns an observable that emits the changed input values
 */
export function useObservableEpic<Inputs extends React.DependencyList, Output>(
    epic: (input$: Observable<Inputs>) => Observable<Output>,
    inputs: Inputs,
): Observable<Output> {
    const inputs$Ref = useConstantFn(() => new BehaviorSubject(inputs));
    const source$Ref = useConstantFn(() => epic(inputs$Ref.current));

    React.useEffect(() => {
        inputs$Ref.current.next(inputs);
        // Explain: inputs$Ref is a constant (and ref)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, inputs);

    return source$Ref.current;
}
