import * as React from 'react';
import { isNullOrUndefined } from '@deltasierra/type-utilities';

/**
 * This type represents a simple JSON value that is compatible with our useLocalStorage hook.
 */
export type LocalStorageValue =
    | LocalStorageValue[]
    | boolean
    | number
    | string
    | { [x: string]: LocalStorageValue }
    | null;

export type UseLocalStorageOptions = {
    /**
     * Whether this local storage value should be synced between browser tabs. This uses the window storage event
     * under the hood and is subject to its limitations.
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/API/Window/storage_event
     * @default false
     */
    updateAcrossBrowserTabs?: boolean;
};

export type UseLocalStorageWithDefaultOptions<T> = UseLocalStorageOptions & {
    /**
     * The fallback/default value to use if there is value for the provided key in local storage.
     */
    initialValue: T;
};

/**
 * Retrieves, stores, and sets a value in local storage, so you can have access to it readily,
 * and leave the browser access to the hook
 *
 * @example
 * const [value, setValue] = useLocalStorage('local_storage_key', '');
 *
 * <input initialValue={value} onChange={setValue} />
 * @param key - the local storage key
 * @param options - The options
 * @param options.initialValue - An initial value if one is required
 * @param options.updateAcrossBrowserTabs - Update the value across tabs
 * @returns - The same api as setState: a tuple with the first item being the value for the given key in
 * local storage, and the second item being a setValue function, which takes in either a new value, or
 * a function with the old value as a param
 */
export function useLocalStorage<T extends LocalStorageValue>(
    key: string,
    options?: UseLocalStorageOptions,
): [value: T | null, setValue: (value: T) => void];
export function useLocalStorage<T extends LocalStorageValue>(
    key: string,
    options?: UseLocalStorageWithDefaultOptions<T>,
): [value: T, setValue: (value: T) => void];
export function useLocalStorage<T extends LocalStorageValue>(
    key: string,
    options?: Partial<UseLocalStorageWithDefaultOptions<T>>,
): [value: T | null, setValue: (value: T) => void] {
    const rawValue = window.localStorage.getItem(key);
    const currentValue = React.useMemo(() => {
        try {
            return rawValue ? (JSON.parse(rawValue) as T) : null;
        } catch (error: unknown) {
            // eslint-disable-next-line no-console
            console.error(`Failed to parse local storage value for key ${key}`);
            return null;
        }
    }, [key, rawValue]);
    const [, setCacheBuster] = React.useState<unknown>();

    const setValue = React.useCallback(
        (value: T): void => {
            if (isNullOrUndefined(value)) {
                window.localStorage.removeItem(key);
            } else {
                window.localStorage.setItem(key, JSON.stringify(value));
            }
        },
        [key],
    );

    React.useEffect(() => {
        const listener = () => {
            setCacheBuster({});
        };

        if (options?.updateAcrossBrowserTabs) {
            window.addEventListener('storage', listener);
        }
        return () => {
            window.removeEventListener('storage', listener);
        };
    }, [options?.updateAcrossBrowserTabs, setCacheBuster]);

    return [currentValue, setValue];
}
