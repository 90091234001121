import * as React from 'react';

/**
 * This is a helper hook to set a value in the session storage.
 *
 * @param key - The key to store the value to
 * @returns - The update function to modify the value in storage
 */
export function useSessionStorageDispatch(key: string): (newValue: string | null | undefined) => void {
    /**
     * To any future readers, I had to do some magic to get this working properly and making sure the save to storage
     * gets called on the unmount. We couldn't use the actual state to update the value because it doesn't persist from
     * a callback to the unmount.
     */
    const [forcedUpdate, forceUpdate] = React.useState<any>();
    const stateRef = React.useRef<{ shouldUpdate: boolean; value?: string | null | undefined }>({
        shouldUpdate: false,
    });

    const saveStateOnEffect = React.useCallback(() => {
        const state = stateRef.current;
        // If we are calling this and should update teh state
        if (state.shouldUpdate) {
            if (state.value === null || state.value === undefined) {
                // If the value is null or undefined we want to clear the entry
                window.sessionStorage.removeItem(key);
            } else {
                // Otherwise we just save it
                window.sessionStorage.setItem(key, state.value);
            }
            stateRef.current = { shouldUpdate: false };
        }
    }, [key, stateRef]);

    // On change of the key, state or saveState function we should recall the saveState
    React.useEffect(() => {
        saveStateOnEffect();
        return () => {
            saveStateOnEffect();
        };
    }, [forcedUpdate, saveStateOnEffect]);

    // Memoize the dispatch
    const dispatchFunc = React.useCallback(
        (newValue: string | null | undefined) => {
            stateRef.current = { shouldUpdate: true, value: newValue };
            forceUpdate({});
        },
        [stateRef],
    );

    return dispatchFunc;
}
