import { useSessionStorageDispatch } from '../useSessionStorageDispatch';
import { useSessionStorageState } from '../useSessionStorageState';

/**
 * This function works similar to the `useLocalStorage` function, however instead of storing the value
 * in the localStorage, it stores it in the sessionStorage. This way the value persists only in this tab,
 * and does not effect any other tabs the user has open. It also does not store the value longer than a
 * session, therfore if they close and re-open their browser, the value will be unset.
 *
 * @param key - The key to store and fetch the value from and to
 * @returns - [The value, The value updater]
 */
export function useSessionStorage(
    key: string,
): [value: string | undefined, updater: (newValue: string | null | undefined) => void] {
    return [useSessionStorageState(key), useSessionStorageDispatch(key)];
}
