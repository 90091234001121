import * as React from 'react';
import { useWindowEvent } from '../useWindowEvent';

/**
 * This is a helper hook to fetch a value from the session storage.
 * The value will be watch the window session storage value, therefore
 * if you change it manually, it will update the state in react.
 *
 * @param key - The key to fetch the value from
 * @returns The value or undefined if it doesn't exist
 */
export function useSessionStorageState(key: string): string | undefined {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = React.useState<string | undefined>(() => {
        // Get from local storage by key
        const item = window.sessionStorage.getItem(key);

        // Parse stored json or if none return initialValue
        return item ?? undefined;
    });

    // Use this to update our current value when it changes across tabs
    const listener = (event: StorageEvent) => {
        if (event.key === key) {
            const newValue = event.newValue ?? undefined;
            if (newValue !== storedValue) {
                setStoredValue(newValue);
            }
        }
    };

    // Called when the value is updated in another tab
    useWindowEvent('storage', listener);

    return storedValue;
}
