import * as React from 'react';

/**
 * Log when a component mounts and unmounts. This isn't a very complex hook, but it's easier to import this then
 * to type it out each time. This hooks is great for development and shouldn't exist in live code.
 *
 * @param name - The name of the component to log to the console
 */
export function useLogMountAndUnmount(name: string): void {
    React.useEffect(() => {
        // eslint-disable-next-line no-console
        console.log(`[Component Mounted]: ${name}`);
        return () => {
            // eslint-disable-next-line no-console
            console.log(`[Component Unmounted]: ${name}`);
        };
    }, [name]);
}
