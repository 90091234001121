import { useCallback, useEffect, useState, useRef } from 'react';

/**
 * Returns a value that is only changed within the bounds of the debounce delay (in ms).
 * A debounced value will only change once the value has not been changed in a certain
 * amount of time (the debounce delay).
 *
 * @example
 * const [value, setValue] = React.useState(0);
 * const dValue = useDebouncedValue(value, 500);
 * <button onClick={setValue(dValue++)}>{dValue}</button>
 *
 * Even if you click the button a bunch, it will only increase once you stop for 500ms.
 * @param value - The value being changed
 * @param delay -  - The debounce delay
 * @returns - the debounced value
 */
export function useDebounce<T>(value: T, delay: number): T {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
}

export function useDebounceFn<TArgs extends any[]>(
    fn: (...args: TArgs) => void,
    delay: number,
    immediate?: boolean,
): (...args: TArgs) => void {
    const timeoutId = useRef<any>();
    // eslint-disable-next-line @typescript-eslint/no-extra-parens
    const fnRef = useRef<(...args: TArgs) => void>(fn);
    fnRef.current = fn;

    return useCallback<typeof fn>(
        (...args) => {
            const callNow = immediate && !timeoutId.current;
            clearTimeout(timeoutId.current);
            timeoutId.current = setTimeout(() => {
                timeoutId.current = undefined;
                if (!immediate) {
                    fnRef.current(...args);
                }
            }, delay);
            if (callNow) {
                fnRef.current(...args);
            }
        },
        [delay, immediate],
    );
}
