import * as React from 'react';

export type useChecklistChecklist<Key = string> = Key[];
export type useChecklistSetChecked<Key = string> = (checked: boolean, key: Key | Key[]) => void;
export type useChecklistClearAll = () => void;

export function useChecklist<Key = string>(
    initialValues?: Key[],
): [checklist: useChecklistChecklist<Key>, setChecked: useChecklistSetChecked<Key>, clearAll: useChecklistClearAll] {
    const [checklist, updateChecklist] = React.useState<Key[]>(initialValues ?? []);

    const setChecked = React.useCallback(
        (checked: boolean, items: Key | Key[]) =>
            updateChecklist(oldList => {
                const itemsToChange = Array.isArray(items) ? items : [items];
                const newList = oldList.filter(item => !itemsToChange.includes(item));
                if (checked) {
                    newList.push(...itemsToChange);
                }
                return newList;
            }),
        [],
    );
    const clearAll = React.useCallback(() => {
        updateChecklist([]);
    }, [updateChecklist]);

    return [checklist, setChecked, clearAll];
}
