import * as React from 'react';

/**
 * @example
 * const scrollRef = useHorizontalScroll();
 * // Component with horizontal scrolling
 * <div ref={scrollRef}>...</div>
 *
 * A hook that wraps logic used for horizontal scrolling on a mouse wheel event.
 * @param scrollAmount - The amount/speed of scrolling when a 'wheel' event occurs. Default value is 50.
 * @returns a ref callback
 */
export function useHorizontalScroll<TElement extends HTMLElement = HTMLElement>(
    scrollAmount = 50,
): (element: TElement | null) => void {
    const [scrollRef, setScrollRef] = React.useState<TElement | null>(null);

    const onRefChange = React.useCallback((element: TElement | null) => setScrollRef(element), []);

    React.useEffect(() => {
        if (scrollRef) {
            const onWheel: React.WheelEventHandler<HTMLElement> = event => {
                if (event.deltaY !== 0 && scrollRef.scrollWidth > scrollRef.clientWidth) {
                    // Trackpads can have non-zero deltaX wheel events with non-zero deltaY events...
                    if (event.deltaX !== 0) {
                        return;
                    }
                    event.preventDefault();
                    const scrollDirection = Math.sign(event.deltaY);
                    scrollRef.scrollTo({
                        left: scrollRef.scrollLeft + scrollDirection * scrollAmount,
                    });
                }
            };
            scrollRef.addEventListener('wheel', onWheel as unknown as EventListener);
            return () => scrollRef.removeEventListener('wheel', onWheel as unknown as EventListener);
        }
        return undefined;
    }, [scrollAmount, scrollRef]);
    return onRefChange;
}
