import * as React from 'react';
import { Observable, Subject } from 'rxjs';
import { useConstantFn } from '../useConstantFn';

/**
 * UseObservableCallback takes an epic initializer, and returns a callback and an observable
 * whose last value is the output of the epic
 *
 * @example
 *
 * const Input = props => {
 *    const [onChange, value$] = useObservableCallback<string, React.ChangeEvent<any>>(
 *        input$ => input$.pipe(map(event => event.target.value)),
 *    );
 *    // value$ emits the latest result from onChange (as a string)
 *    // it can be subscribed to using useObservableSubscription or made into state using useObservableState
 *    // For instance, if you want to control the value of the input:
 *    // const value = useObservableState($value);
 *    return <input type="text" onChange={onChange} />
 * }
 * @param epic - a function that takes an observable as input and outputs another observable
 * @param selector - optional function to select which parameter in the callback will be emitted
 * @returns a callback, which will send a value through the epic, and an observable with the
 * latest emitted value
 */
export function useObservableCallback<Output, Input = Output, Params extends Readonly<any[]> = [Input]>(
    epic: (input$: Observable<Input>) => Observable<Output>,
    selector?: (args: Params) => Input,
): [(...args: Params) => void, Observable<Output>] {
    const input$Ref = useConstantFn(() => new Subject<Input>());
    const output$Ref = useConstantFn(() => epic(input$Ref.current));

    const callbackRef = React.useRef((...args: Params) => {
        input$Ref.current.next(selector ? selector(args) : args[0]);
    });

    return [callbackRef.current, output$Ref.current];
}
