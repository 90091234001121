import * as React from 'react';
import { debounceTime, map } from 'rxjs/operators';
import { noop } from '@deltasierra/object-utilities';
import { useObservableCallback } from '../useObservableCallback';
import { useObservableState } from '../useObservableState';
import { useObservableSubscription } from '../useObservableSubscription';
import { UseControlledInputProps } from '../useControlledInput';
import { useObservable } from '../useObservable';

export type UseControlledSearchInputArgs = {
    initialValue?: string;
    interval?: number;
    onValueChange?: (val: string) => void;
    onSearchTermValueChange?: (val: string) => void;
};

export type UseControlledSearchInputResult = {
    value: string;
    onChange: (val: string) => void;
    searchTerm: string;
    inputProps: UseControlledInputProps;
};
export const useControlledSearchInput = ({
    initialValue = '',
    interval = 500,
    onSearchTermValueChange = noop,
    onValueChange = noop,
}: UseControlledSearchInputArgs): UseControlledSearchInputResult => {
    const [onChange, value$] = useObservableCallback<
        string,
        React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string
    >(input$ =>
        input$.pipe(
            map(newValue => {
                if (isChangeEventNotString(newValue)) {
                    return newValue.target.value;
                } else {
                    return newValue;
                }
            }),
        ),
    );
    const value = useObservableState(value$, initialValue);

    const searchTerm$ = useObservable(() => value$.pipe(debounceTime(interval)));
    const searchTerm = useObservableState(searchTerm$, initialValue);

    useObservableSubscription(value$, onValueChange);
    useObservableSubscription(searchTerm$, onSearchTermValueChange);

    return React.useMemo(
        () => ({
            inputProps: { onChange, value },
            onChange,
            searchTerm,
            value,
        }),
        [onChange, searchTerm, value],
    );
};

const isChangeEventNotString = (
    value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string,
): value is React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> => typeof value !== 'string';
