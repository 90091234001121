import * as React from 'react';
import { isNullOrUndefined } from '@deltasierra/type-utilities';

/**
 * A quick and simple way of creating React Context's and a hook for accessing said context. This wrapper allows you to
 * define contexts in a typesafe manor without having to provide an initial value. Remember to provide the context in
 * a parent component!
 *
 * @example
 * // Simply define the name of the context and the "use" hook:
 * const [AuthUserContext, useAuthUser] = createContextAndUseContextHook<User>('AuthUserContext');
 * // Provide a default value in a React component somewhere:
 * <AuthUserContext.Provider value={...}>...</AuthUserContext.Provider>
 * // Then use the convenient hook to access your context!
 * const user = useAuthUser(); // Will throw an error if the context hasn't been provided in a parent component
 * @param name - The name of this context. This is mostly for debugging and errors
 * @returns A tuple containing the React Context and a React Hook for accessing the context's value
 */
export function createContextAndUseContextHook<T>(
    name: string,
): [React.Context<Exclude<T, null | undefined>>, () => Exclude<T, null | undefined>] {
    const context = React.createContext<Exclude<T, null | undefined>>(null!);
    const useContext = () => {
        const loadedContext = React.useContext(context);
        if (isNullOrUndefined(loadedContext)) {
            const reason = 'It could be that you\'re using a context hook without a parent Provider.';
            throw new Error(`Context "${name}" has not been provided. ${reason}`);
        }
        return loadedContext;
    };
    return [context, useContext];
}
