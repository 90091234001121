/* eslint-disable @typescript-eslint/naming-convention */
import * as React from 'react';
import { useConstantFn } from '../useConstantFn';

/**
 * Adds event listener to an element ref and fires provided handler
 *
 * @example
 *  function MyComponent(): JSX.Element {
 *      const ref = React.useRef<HTMLInputElement>(null);
 *      // Will console.log the new value whenever the 'change' event is fired
 *      useEventListener('change', ev => console.log(ev.target.value), ref);
 *
 *      return <input ref={ref} />;
 *  }
 * @param eventName - name of the event to listen to
 * @param listener - the function to be called when the event is fired on the element
 * @param elementRef - a react ref pointing to an html element
 */
export function useEventListener<TEvent extends keyof HTMLElementEventMap>(
    eventName: TEvent,
    listener: (ev: HTMLElementEventMap[TEvent]) => void,
    elementRef: React.RefObject<HTMLElement>,
): void {
    const listenerRef = React.useRef(listener);
    const eventNameRef = useConstantFn(() => eventName);

    React.useEffect(() => {
        listenerRef.current = listener;
    }, [listener]);

    React.useEffect(() => {
        const _eventName = eventNameRef.current;
        const _element = elementRef.current;

        if (_element) {
            const listen = (ev: HTMLElementEventMap[TEvent]) => listenerRef.current(ev);
            _element.addEventListener(_eventName, listen);
            return () => _element.removeEventListener(_eventName, listen);
        }
        return () => undefined;
    }, [eventNameRef, elementRef, listenerRef]);
}
