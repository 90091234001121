import { RefObject, useEffect, useRef } from 'react';

export type UseDoubleClickInputs = {
    delay: number;
    onDoubleClick: () => void;
    onSingleClick: () => void;
};

/**
 * Detects double clicks and invokes the relevant callback in case of a double click (or single click)
 *
 * @example
 * const ref = useDoubleClick({
 *  delay: 200,
 *  onDoubleClick: () => console.log('Double'),
 *  onSingleClick: () => console.log('Single')
 * });
 * <button ref={ref}>{'Click me!'}</button>
 * @param params - an object with the following fields:
 * @param params.delay - The double click window in ms
 * @param params.onDoubleClick - The callback to be invoked in case of a double click
 * @param params.onSingleClick - The callback to be invoked in case of a single click
 * @returns - A ref to the element, to be passed into the ref prop
 */
export const useDoubleClick = <T extends HTMLElement>({
    delay = 300,
    onDoubleClick = () => null,
    onSingleClick = () => null,
}: UseDoubleClickInputs): RefObject<T> => {
    const elementRef = useRef<T>(null);
    const countRef = useRef(0);
    const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
    const singleClickRef = useRef<() => void>();
    const doubleClickRef = useRef<() => void>();

    useEffect(() => {
        singleClickRef.current = onSingleClick;
        doubleClickRef.current = onDoubleClick;
    });

    useEffect(() => {
        const element = elementRef.current;
        function handler() {
            const isDoubleClick = countRef.current + 1 === 2;
            if (timerRef.current && isDoubleClick) {
                clearTimeout(timerRef.current);
                timerRef.current = null;
                countRef.current = 0;
                if (doubleClickRef.current) {
                    doubleClickRef.current();
                }
            } else if (singleClickRef.current) {
                singleClickRef.current();
            }

            if (!timerRef.current) {
                countRef.current++;
                const timer = setTimeout(() => {
                    if (timerRef.current) {
                        clearTimeout(timerRef.current);
                    }
                    countRef.current = 0;
                    timerRef.current = null;
                }, delay);
                timerRef.current = timer;
            }
        }

        if (element) {
            element.addEventListener('click', handler);
        }

        return () => {
            if (element) {
                element.removeEventListener('click', handler);
            }
        };
    }, [delay]);

    return elementRef;
};
