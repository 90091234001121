import { isNullOrUndefined } from '@deltasierra/type-utilities';
import * as React from 'react';

// TODO: this should be the real useToggle hook, it is much more ergonomic.
// However, it is outside the scope of this branch to refactor every use of useToggle
/**
 * Keeps track of a boolean value, and allows user to toggle or set the value
 *
 * @example
 *  function MyComponent(): JSX.Element {
 *      const [isOpen, toggle] = useToggleRedux();
 *      return (
 *          <div>
 *              <span>{`isOpen = ${isOpen}`}</span>
 *              <button onClick={() => toggle()}>{'Toggle'}</button>
 *              <button onClick={() => toggle(true)}>{'Set to true'}</button>
 *              <button onClick={() => toggle(false)}>{'Set to false'}</button>
 *          </div>
 *      )
 *  }
 * @param initial - optional initial value (true or false) defaults to false
 * @returns a tuple with [the current state, a toggle function for the state]
 */
export function useToggleRedux(initial = false): [boolean, (val?: boolean) => void] {
    return React.useReducer(
        (state: boolean, action?: boolean) => (isNullOrUndefined(action) ? !state : action),
        initial,
    );
}
