import * as React from 'react';
import { Observable, Subscription } from 'rxjs';

export function useObservableSubscription<Input>(
    input$: Observable<Input>,
    next?: ((value: Input) => void) | null,
): React.MutableRefObject<Subscription | undefined> {
    const input$Ref = React.useRef(input$);
    const nextRef = React.useRef(next);

    const subscriptionRef = React.useRef<Subscription>();

    React.useLayoutEffect(() => {
        input$Ref.current = input$;
        nextRef.current = next;
    });

    React.useEffect(() => {
        const localInput$ = input$Ref.current;
        const subscription = localInput$.subscribe({
            next: value => {
                if (localInput$ !== input$Ref.current) {
                    return;
                }
                if (nextRef.current) {
                    // eslint-disable-next-line consistent-return
                    return nextRef.current(value);
                }
            },
        });
        subscriptionRef.current = subscription;

        return () => subscription.unsubscribe();
    }, [input$]);

    return subscriptionRef;
}
