import * as React from 'react';

export class ChecklistMap<Key = string> {
    public readonly internalMap: Map<Key, ChecklistItemState>;

    public constructor(map: Map<Key, ChecklistItemState>) {
        this.internalMap = map;
    }

    public toArray(): Array<[Key, ChecklistItemState]> {
        return Array.from(this.internalMap);
    }

    public toCheckedArray(): Key[] {
        return this.toArray()
            .filter(([, state]) => state.checked)
            .map(([key]) => key);
    }

    public map<R>(mappingFunc: (key: Key, state: ChecklistItemState) => R): R[] {
        return this.toArray().map(([key, state]) => mappingFunc(key, state));
    }

    public filter(...params: Parameters<Array<[Key, ChecklistItemState]>['filter']>): ChecklistMap<Key> {
        return new ChecklistMap(new Map(Array.from(this.internalMap).filter(...params)));
    }

    public isChecked(key: Key, defaultValueIfNotExist: boolean | null = false): boolean {
        return (this.internalMap.get(key)?.checked ?? defaultValueIfNotExist) === true;
    }

    public isCheckedNonNull(key: Key): boolean {
        return (this.internalMap.get(key)?.checked ?? null) !== null;
    }

    public isIndeterminate(key: Key, defaultValueIfNotExist: boolean | null = false): boolean {
        return (this.internalMap.get(key)?.indeterminate ?? defaultValueIfNotExist) === true;
    }

    public isIndeterminateNonNull(key: Key): boolean {
        return (this.internalMap.get(key)?.indeterminate ?? null) !== null;
    }
}

export type ChecklistItemState = {
    checked: boolean | null;
    indeterminate: boolean | null;
};

export type ChecklistItemStateHandler<Key = string> = (key: Key | Key[], state: Partial<ChecklistItemState>) => void;

export function useChecklistState<Key = string>(): [
    checklist: ChecklistMap<Key>,
    setChecked: ChecklistItemStateHandler<Key>,
    reset: () => void,
] {
    const [checklist, updateChecklist] = React.useState<ChecklistMap<Key>>(new ChecklistMap(new Map()));

    const setChecked: ChecklistItemStateHandler<Key> = React.useCallback(
        (items, state) => {
            updateChecklist(old => {
                const newChecklist = new Map(old.internalMap);
                const arrayToCheck = Array.isArray(items) ? items : [items];
                for (const item of arrayToCheck) {
                    // Merge old state + new state
                    newChecklist.set(item, {
                        checked: null,
                        indeterminate: null,
                        ...newChecklist.get(item),
                        ...state,
                    });
                }
                return new ChecklistMap(newChecklist);
            });
        },
        [updateChecklist],
    );

    const reset = React.useCallback(() => {
        updateChecklist(new ChecklistMap(new Map()));
    }, [updateChecklist]);

    return [checklist, setChecked, reset];
}
