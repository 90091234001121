import * as React from 'react';

/**
 * Takes in a factory function, and returns a value that will not change once init is called
 *
 * @param init - factory that returns constant value. Will only be called once.
 * @returns the constant, which will never change once the factory init fn is called
 */
export function useConstantFn<T>(init: () => T): React.MutableRefObject<T> {
    const firstPass = React.useRef(true);
    const ref = React.useRef<T | null>(null);
    if (firstPass.current) {
        firstPass.current = false;
        ref.current = init();
    }
    return ref as React.MutableRefObject<T>;
}
