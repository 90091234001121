import * as React from 'react';
import { Observable, Subject } from 'rxjs';
import { useObservableSubscription } from '../useObservableSubscription';
import { useConstantFn } from '../useConstantFn';

/**
 * UseObservableEpicState takes an epic and initialValue as state, and returns a value and setValue
 * The value is passed through the epic whenever the setValue is called
 *
 * @example
 * const [debouncedValue, onValueChange] = useObservableEpicState(
 *     input$ => input$.pipe(debounceTime(500)),
 *     '',
 * );
 *
 * // The text in the resulting div will only change once the user stops typing for 500ms
 * return (
 *     <input type="text" onChange={ev => onValueChange(ev.target.value)} />
 *     <div>{debouncedValue}</div>
 * );
 * @param epic - A function that takes an observable as param and returns an observable
 * @param initialState - optional initial state of the returning value.
 * @returns The value to store, and an setValue function.  Returns undefined as value if no initialValue
 */
export function useObservableEpicState<State, Input = State>(
    epic: (input$: Observable<Input>, initialState: State) => Observable<State>,
    initialState: State,
): [State, (input: Input) => void];
export function useObservableEpicState<State, Input = State>(
    epic: (input$: Observable<Input>, initialState: State | undefined) => Observable<State>,
    initialState?: State,
): [State | undefined, (input: Input) => void] {
    const [state, setState] = React.useState<State | undefined>(initialState);

    const input$Ref = useConstantFn<Subject<Input>>(() => new Subject());

    const state$ = useConstantFn(() => epic(input$Ref.current, state)).current;
    const callback = React.useRef((newState: Input) => input$Ref.current.next(newState)).current;

    useObservableSubscription(state$, setState);

    return [state, callback];
}
