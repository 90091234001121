import * as React from 'react';

/**
 * Creates a ref that you can attach to an element and listen to when it hits the bottom of the element
 *
 * @param onScrolledBottom - The callback to call when scrolled to the bottom
 * @param offset - The offset in pixels from the bottom
 * @returns - The ref of the container to attach to
 */
export function useOnScrollBottom<T extends HTMLElement>(
    onScrolledBottom: () => void,
    offset = 0,
): React.RefCallback<T> {
    const [ref, setRef] = React.useState<T | null>(null);

    const handleScroll = React.useCallback(() => {
        const currentRef = ref;

        if (!currentRef) {
            return;
        }

        const scrollContainerBottomPosition = Math.round(currentRef.scrollTop + currentRef.clientHeight);
        const scrollPosition = Math.round(currentRef.scrollHeight - offset);

        if (scrollPosition <= scrollContainerBottomPosition) {
            onScrolledBottom();
        }
    }, [onScrolledBottom, offset, ref]);

    React.useEffect(() => {
        // Workaround for cases where this useEffect fires before the ref gets assigned
        const currentRef = ref;
        if (!currentRef) {
            return undefined;
        }

        currentRef.addEventListener('scroll', handleScroll);

        return () => {
            currentRef.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll, ref]);

    const callbackRef = React.useCallback((newValue: React.SetStateAction<T | null>) => {
        if (newValue !== null) {
            setRef(newValue);
        }
    }, []);

    return callbackRef;
}
