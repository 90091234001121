import * as React from 'react';
import { Observable } from 'rxjs';
import { useObservableSubscription } from '../useObservableSubscription';

/**
 * UseObservableState takes an observable and returns the last emitted value
 *
 * @example
 * const seconds = useObservableState(interval(1000), 0);
 *
 * return <div>{`Seconds since start: ${seconds}`}</div>
 * @param state$ - an observable, which sets the state to whatever it emits
 * @param initialState - the state of the returned value until the observable emits for the first time
 * @returns the last emitted value of the provided observable
 */
export function useObservableState<State>(state$: Observable<State>, initialState: State | (() => State)): State;
export function useObservableState<State>(
    state$: Observable<State>,
    initialState?: State | (() => State),
): State | undefined {
    const [state, setState] = React.useState<State | undefined>(initialState);

    useObservableSubscription(state$, setState);

    return state;
}
