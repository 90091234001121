import * as React from 'react';

type EventHandler<T extends Event = Event> = (e: T) => void;

/**
 * Easy wrapper for using a window event listener
 *
 * @param eventName - The event name
 * @param handler - The handler
 */
export function useWindowEvent<K extends keyof WindowEventMap>(
    eventName: K,
    handler: EventHandler<WindowEventMap[K]>,
): void {
    const handlerRef = React.useRef<typeof handler>();

    React.useEffect(() => {
        handlerRef.current = handler;
    }, [handler]);

    React.useEffect(() => {
        const eventListener: typeof handler = event => {
            if (!handlerRef.current) {
                return;
            }
            handlerRef.current(event);
        };

        window.addEventListener(eventName, eventListener);

        return () => {
            window.removeEventListener(eventName, eventListener);
        };
    }, [eventName]);
}
