import { RefObject, useCallback, useLayoutEffect, useRef, useState } from 'react';
import { useResizeObserver } from '../useResizeObserver';

export const useElementWidth = <T extends HTMLElement>(): { ref: RefObject<T>; width: number } => {
    const ref = useRef<T>(null);
    const [width, setWidth] = useState(0);

    const updateSize = useCallback(() => {
        if (ref.current !== null) {
            setWidth(ref.current.offsetWidth);
        }
    }, []);

    useResizeObserver<T>({
        onResize: updateSize,
        ref,
    });

    useLayoutEffect(() => {
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, [updateSize]);
    return { ref, width };
};
