import * as React from 'react';

export const useInterval = (interval: number, callback: () => void): void => {
    const callbackRef = React.useRef<() => void>();

    React.useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    React.useEffect(() => {
        const tick = () => {
            if (callbackRef.current) {
                callbackRef.current();
            }
        };

        const id = setInterval(() => {
            tick();
        }, interval);

        return () => clearInterval(id);
    }, [interval]);
};
