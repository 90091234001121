import * as React from 'react';

/**
 * Use this to do something on key up
 *
 * @param keys - The key/s to listen for
 * @param memoizedFunc - The function to execute
 */
export function useOnKeyUp(keys: string[] | string, memoizedFunc: () => void): void {
    React.useEffect(() => {
        const upHandler = (ev: KeyboardEvent) => {
            if ((Array.isArray(keys) ? keys : [keys]).includes(ev.key)) {
                memoizedFunc();
            }
        };
        window.addEventListener('keyup', upHandler);

        // Remove event listeners on cleanup
        return () => {
            window.removeEventListener('keyup', upHandler);
        };
    }, [keys, memoizedFunc]);
}
