import * as React from 'react';

/**
 * A convenient hook that will load an image, and inform you when the image has finished loading
 *
 * @param src - The image source URL
 * @param additionalDeps - If this hook should re-render when something else changes
 * @returns An tuple of [loadingState, refChangeFunction]
 */
export function useImageLoaded(
    src?: string,
    additionalDeps?: unknown[],
): [boolean, (element: HTMLImageElement) => void] {
    const imageRef = React.useRef<HTMLImageElement | null>(null);
    const [loading, setLoading] = React.useState(true);

    const onImageLoaded = React.useCallback(() => setLoading(false), []);

    React.useEffect(() => {
        setLoading(true);
    }, [src, additionalDeps]);

    React.useEffect(() => {
        imageRef.current?.addEventListener('load', onImageLoaded);
        return () => imageRef.current?.removeEventListener('load', onImageLoaded);
    }, [onImageLoaded]);

    const onRefChange = React.useCallback((element: HTMLImageElement) => {
        imageRef.current = element;
    }, []);
    return [loading, onRefChange];
}
