import React from 'react';

export type usePollerReturnType = {
    onSuccess: () => void;
    onError: () => void;
    startPolling: () => void;
    stopPolling: () => void;
};

export function usePoller(pollCallback: () => void, pollIntervalMs: number): usePollerReturnType {
    const [isPolling, setIsPolling] = React.useState(false);
    const [repollCount, setRepollCount] = React.useState(0);
    const [retryErrorCount, setRetryErrorCount] = React.useState(0);

    // When the repollCount or retryErrorCount is updated, we start a repoller
    React.useEffect(() => {
        if (!(repollCount > 0 || retryErrorCount > 0) || !isPolling) {
            return;
        }

        const timeoutHandle = setTimeout(() => {
            pollCallback();
        }, pollIntervalMs);
        // eslint-disable-next-line consistent-return
        return () => {
            clearTimeout(timeoutHandle);
        };
    }, [isPolling, pollCallback, pollIntervalMs, repollCount, retryErrorCount]);

    const startPolling = React.useCallback(() => {
        setIsPolling(true);
        setRepollCount(count => count + 1);
    }, [setIsPolling, setRepollCount]);

    const stopPolling = React.useCallback(() => {
        setIsPolling(false);
    }, [setIsPolling]);

    const onSuccess = React.useCallback(() => {
        setRepollCount(count => count + 1);
    }, [setRepollCount]);

    const onError = React.useCallback(() => {
        setRetryErrorCount(count => count + 1);
    }, [setRetryErrorCount]);

    return {
        onError,
        onSuccess,
        startPolling,
        stopPolling,
    };
}
