import { useReducer, Dispatch } from 'react';
import { assertNever } from '@deltasierra/type-utilities';

export const useToggle = (initialValue = false): [boolean, Dispatch<'toggle' | 'toggleOff' | 'toggleOn'>] =>
    useReducer((state: boolean, action: 'toggle' | 'toggleOff' | 'toggleOn') => {
        switch (action) {
            case 'toggle':
                return !state;
            case 'toggleOn':
                return true;
            case 'toggleOff':
                return false;
            default:
                return assertNever(action);
        }
    }, initialValue);
