import { MutableRefObject, useEffect, useState, useRef } from 'react';

/**
 * Returns a value that is only changed within the bounds of the throttle delay (in ms).
 * A throttled value will only change once every [delay] ms.
 *
 * @example
 * const [value, setValue] = React.useState(0);
 * const tValue = useThrottledValue(value, 500);
 * <button onClick={setValue(tValue++)}>{tValue}</button>
 *
 * Even if you click the button a bunch, it will only increase every 500ms.
 * @param value - The value being changed
 * @param delay - The throttle delay
 * @returns - the throttled value
 */
export function useThrottle<T>(value: T, delay: number): T {
    const [throttledValue, setThrottledValue] = useState(value);
    const lastRan: MutableRefObject<number> = useRef(Date.now());

    useEffect(() => {
        const handler = setTimeout(() => {
            if (Date.now() - lastRan.current >= delay) {
                setThrottledValue(value);
                lastRan.current = Date.now();
            }
        }, delay - (Date.now() - lastRan.current));

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return throttledValue;
}
