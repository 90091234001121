import { DependencyList, RefObject, useCallback, useEffect, useRef } from 'react';

/**
 * Trigger the callback whenever the user clicks outside an element.
 *
 * @example
 * const [count, setCount] = React.useState(0);
 * const ref = useClickOutside(() => setCount(prev => prev + 1), [setCount]);
 * return <div ref={ref}>Clicked: {count}</div>
 * @param callback - The callback to invoke
 * @param deps - Dependencies for the callback
 * @returns The React reference that should be attached to the element to monitor
 */
export function useClickOutside<TElement extends HTMLElement>(
    callback: () => void,
    deps: DependencyList,
): RefObject<TElement> {
    const ref = useRef<TElement>(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const memoizedCallback = useCallback(callback, deps);

    const listener = useCallback(
        (event: MouseEvent) => {
            if (ref.current && event.target && !ref.current.contains(event.target as Node)) {
                memoizedCallback();
            }
        },
        [ref, memoizedCallback],
    );

    useEffect(() => {
        document.addEventListener('mousedown', listener);
        return () => {
            document.removeEventListener('mousedown', listener);
        };
    }, [listener]);
    return ref;
}
