import * as React from 'react';

export const useOnHitPageBottom = (callback: () => void, doNotCall = false): void => {
    const handleScroll = React.useCallback((fn: () => void, loadingFlag: boolean) => {
        if (
            window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight &&
            !loadingFlag
        ) {
            fn();
        }
    }, []);

    const handleScrollRef = React.useRef(() => handleScroll(callback, doNotCall));

    React.useEffect(() => {
        handleScrollRef.current = () => handleScroll(callback, doNotCall);
    }, [handleScroll, callback, doNotCall]);

    React.useEffect(() => {
        window.addEventListener('scroll', handleScrollRef.current);
        return () => window.removeEventListener('scroll', handleScrollRef.current);
    });
};
