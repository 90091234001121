import * as React from 'react';

export function mergeRefs<T = any>(refs: Array<React.LegacyRef<T> | React.MutableRefObject<T>>): React.RefCallback<T> {
    return value => {
        refs.forEach(ref => {
            if (typeof ref === 'function') {
                ref(value);
            } else if (ref !== null) {
                (ref as React.MutableRefObject<T | null>).current = value;
            }
        });
    };
}
